import React, { useCallback, useEffect, useState } from 'react';

import {
  Currency,
  Product,
  LineItem,
  Subscription,
  Fulfilment,
  DraftCustomOrder,
} from '#types';

import { FormProvider } from '#context/FormContext';

import { OrderFormMode } from '#components/orders/OrderForm';

import useNotifications from '#hooks/useNotifications';
import useForm from '#hooks/useForm';
import useAdjustments from '#hooks/useAdjustments';
import useOrders from '#hooks/useOrders';
import useProducts from '#hooks/useProducts';
import useOptions from '#hooks/useOptions';
import useSubscriptions from '#hooks/useSubscriptions';

import { settings } from '#materials';
import Icon from '#materials/Icon';
import {
  Action,
  CellElement,
  TableActionCell,
  TableTextInputCell,
  TableSelectCell,
  TableCell,
} from '#materials/TableCell';

import { listRecords } from '#utils/data';
import { formatCurrency } from '#utils/currency';
import locale, { localize } from '#utils/locale';

const localeTableKeys = locale.keys.tables.lineItems;
const localeNotificationKeys = locale.keys.notifications.lineItems;

export const TABLE_KEYS = {
  icon : 'icon',
  id : 'id',
  productSku : 'productSku',
  product : 'product',
  period : 'period',
  requestedQty : 'reqQuantity',
  fulfilledQty : 'fulfilledQty',
  unit : 'unit',
  price : 'price',
  adjustedPrice : 'adjustedPrice',
  actions : 'actions',
} as const;
export type TableKey = typeof TABLE_KEYS[keyof typeof TABLE_KEYS];
export const defaultTableKeys = Object.values(TABLE_KEYS);

function generateLabel(product : Product) { return product.name }
function generateKey(product : Product) {
  return `${product.id || product.sku || product.name}`
}
function areProductsEqual(a : Product, b : Product) {
  return a.id === b.id;
}

interface LineItemRowProps {
  order? : DraftCustomOrder;
  fulfilment? : Fulfilment;
  lineItem : LineItem;
  subscription? : Subscription;
  period? : number;
  mode? : OrderFormMode;
  disableProduct? : boolean;
  editing? : boolean;
  disabled? : boolean;
  fade? : boolean;
  icon? : React.ReactNode;
  tableKeys? : TableKey[];
  generateActions? : (lineItem : LineItem) => CellElement;
  onSave? : (
    lineItem : LineItem,
    subscriptionOptions : { period : number },
  ) => void | Promise<void>;
  onCancel? : () => void | Promise<void>;
  onProductChange? : (product : Product) => void | Promise<void>;
  onUpdateFulfilment? : (fulfilment : Fulfilment) => void | Promise<void>;
}

function LineItemRowControl({
  fulfilment : initialFulfilment,
  lineItem : fallback,
  order,
  subscription,
  period : defaultPeriod,
  mode = 'fulfilment',
  disableProduct = false,
  disabled = false,
  fade = false,
  icon,
  tableKeys = defaultTableKeys,
  generateActions,
  onSave,
  onCancel,
  onProductChange,
  onUpdateFulfilment,
} : LineItemRowProps) {
  const { createNotification } = useNotifications();
  const { state : lineItem, dispatch, reset, editing } = useForm<LineItem>();
  const { products } = useProducts();
  const { generateDefaultAdjustment } = useAdjustments();
  const {
    addAdjustmentToFulfilment,
    calculateLinePrice,
  } = useOrders();
  const { getLineItemFulfilment } = useOptions();
  const { isLineItemRecurring } = useSubscriptions();

  const fulfilment = initialFulfilment
    ?? (order ? getLineItemFulfilment(fallback, order) : null);

  const [period, setPeriod] = useState<number>(defaultPeriod
    ?? subscription?.period
    ?? 0);
  const [allProducts, setAllProducts] = useState<Product[]>(
    listRecords(products)
  );
  const [periodOptions, setPeriodOptions] = useState<number[]>(
    (lineItem && isLineItemRecurring(lineItem) && period)
      ? [1, 2, 3, 4]
      : [0, 1, 2, 3, 4]
  );

  const [product, setProduct] = useState(allProducts
    .find((p) => p.id === ((mode === 'fulfilment')
        ? (fulfilment?.fulfilledProductId
          ?? fulfilment?.requestedProductId
          ?? lineItem?.productId)
        : lineItem?.productId)) ?? null
  );
  const [quantity, setQuantity] = useState(
    fulfilment?.fulfilledQty
      ?? fulfilment?.requestedQty
      ?? subscription?.quantity
      ?? lineItem?.quantity
      ?? fallback?.quantity
  );
  const [reqQuantity, setReqQuantity] = useState<number>(
    fulfilment?.requestedQty
      ?? subscription?.quantity
      ?? lineItem?.quantity
      ?? fallback?.quantity
  );

  const [linePrice, setLinePrice] = useState<Currency | null>(
    lineItem
      ? calculateLinePrice(lineItem, order)
      : (product?.price ?? null)
  );
  const [unadjustedPrice, setUnadjustedPrice] = useState<Currency | null>(
    lineItem
      ? calculateLinePrice(
        lineItem,
        order,
        { adjustments : [], quantity : quantity },
      )
      : (product?.price ?? null)
  );

  const unitPrice = lineItem
    ? calculateLinePrice(lineItem, null, { quantity : 1 })
    : null;

  const handleSave = useCallback(async () => {
    if (!lineItem) return;
    if (onSave) await onSave(lineItem, { period });
  }, [onSave, lineItem, period]);

  const handleCancel = useCallback(async () => {
    if (onCancel) await onCancel();
    reset();
  }, [onCancel, reset]);

  const handleProduct = useCallback(async (product : Product | null) => {
    if (!product || !product.id) return;
    onProductChange?.(product);

    setProduct(product);

    if (mode === 'fulfilment') {
      if (!fulfilment) return;
      await onUpdateFulfilment?.({
        ...fulfilment,
        fulfilledProductId : product.id,
      });
      return;
    }

    dispatch({ productId : product.id });
  }, [
    fulfilment,
    mode,
    onUpdateFulfilment,
    onProductChange,
    dispatch,
  ]);

  const handleQuantity = useCallback((quantity : number | null) => {
    if (quantity === null) return;
    setQuantity(quantity);
    if (mode !== 'fulfilment') setReqQuantity(quantity);
    dispatch({ quantity : quantity ?? 1 });
  }, [mode, dispatch]);

  const handlePrice = useCallback((price : number | null) => {
    if (!linePrice) return;
    if (price === null) return;
    const amount = Math.round((price ?? 0) / (linePrice.increment));
    setLinePrice({
      ...linePrice,
      amount : amount,
      calculatedValue : amount * (linePrice.increment),
    });
  }, [linePrice]);

  const handleBlurQuantity = useCallback(async () => {
    if (mode !== 'fulfilment') return;
    if (
      onUpdateFulfilment
        && fulfilment
        && (quantity !== fulfilment.fulfilledQty)
    ) {
      const updatedFulfilment = {
        ...fulfilment,
        fulfilledQty : quantity,
      }
      await onUpdateFulfilment(updatedFulfilment);
    }
  }, [
    mode,
    fulfilment,
    quantity,
    onUpdateFulfilment,
  ])

  const handleBlurPrice = useCallback(async () => {
    if (mode !== 'fulfilment') return;
    if (!fulfilment || !lineItem || !linePrice) return;

    const estimatedPrice = calculateLinePrice(
      lineItem,
      order,
    );
    if (!estimatedPrice) return;
    const diff = Math.round(linePrice.amount - estimatedPrice.amount);
    if (diff === 0) return;

    const adjToApply = {
      ...generateDefaultAdjustment({ scope : 'lineItem' }),
      currency : {
        amount : diff,
        currencyCode : estimatedPrice.currencyCode,
        increment : estimatedPrice.increment,
        calculatedValue : diff * estimatedPrice.increment,
      },
    }

    const result = await addAdjustmentToFulfilment(fulfilment, adjToApply);
    if (result) {
      createNotification({
        key : 'add-adjustment-success',
        message : localize(
          localeNotificationKeys.applyAdjustment.success),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.primary,
      });
    } else {
      setLinePrice(estimatedPrice);
      createNotification({
        key : 'add-adjustment-error',
        message : localize(
          localeNotificationKeys.applyAdjustment.error),
        icon : (<Icon icon={settings.svgIcons.receipt} />),
        colour : settings.colours.alert.alert,
      });
    }
  }, [
    order,
    mode,
    lineItem,
    fulfilment,
    linePrice,
    generateDefaultAdjustment,
    calculateLinePrice,
    addAdjustmentToFulfilment,
    createNotification,
  ])

  const gernerateEditActions = useCallback(() => {
    return (
      <TableActionCell width={settings.dimensions.xsmall}>
        <Action
          label={localize(localeTableKeys.actions.save)}
          onClick={handleSave}
          disabled={disabled}
        >
          <Icon icon={settings.svgIcons.check} />
        </Action>
        <Action
          label={localize(localeTableKeys.actions.cancel)}
          onClick={handleCancel}
          disabled={disabled}
          colour={settings.colours.button.alert}
        >
          <Icon icon={settings.svgIcons.clear} />
        </Action>
      </TableActionCell>
    );
  }, [disabled, handleSave, handleCancel]);

  const rowGenerator = useCallback((key : string) => {
    const greyOut = fade || (!fulfilment && mode === 'fulfilment');

    const partialFulfilment = quantity !== reqQuantity;

    const adjusted = linePrice?.calculatedValue
      !== unadjustedPrice?.calculatedValue;
    const unadjustedFormatted = unadjustedPrice
      ? formatCurrency(unadjustedPrice)
      : '';

    switch (key) {
      case TABLE_KEYS.id: return (
        <TableCell faded={greyOut}>
          { (lineItem?.id ?? 0) > 0
            ? `# ${lineItem?.id}` + ((fulfilment && mode === 'fulfilment')
              ? `-${fulfilment.id}`
              : '')
            : ''
          }
        </TableCell>
      );
      case TABLE_KEYS.icon: return icon
        ? (<TableCell faded={greyOut}>{ icon }</TableCell>)
        : (
          <TableCell faded={greyOut}>
            { (period > 0) && (
              <Icon
                icon={settings.svgIcons.refresh}
                colour={settings.colours.button.primary}
              />
            ) }
          </TableCell>
        );
      case TABLE_KEYS.productSku: return (
        <TableCell width={settings.dimensions.small} faded={greyOut}>
          { product?.sku }
        </TableCell>
      );
      case TABLE_KEYS.product: return (
        disableProduct
          ? (<TableCell width={settings.dimensions.small} faded={greyOut}>
            { product?.name }
            </TableCell>)
          : (<TableSelectCell
            width={settings.dimensions.medium}
            label={localize(localeTableKeys.headings.product)}
            selected={product}
            options={allProducts}
            onChange={handleProduct}
            labelGenerator={generateLabel}
            keyGenerator={generateKey}
            isEqual={areProductsEqual}
            disableClear
            disabled={
              disabled
                || !editing
                || disableProduct
                || (mode === 'fulfilment' && !fulfilment)
            }
          />)
      );
      case TABLE_KEYS.period: return (
        <TableSelectCell
          selected={period}
          options={periodOptions}
          onChange={setPeriod}
          labelGenerator={
            (i : number) => localize(localeTableKeys.values.period[i])
          }
          faded={greyOut}
          disabled={
            disabled
              || !editing
              || mode === 'fulfilment'
          }
          width={settings.dimensions.small}
        />
      );
      case TABLE_KEYS.requestedQty: return (
        (editing && (mode === 'edit'))
          ? (
            <TableTextInputCell
              id={`${lineItem?.id}-quantity`}
              label={localize(localeTableKeys.headings.quantity)}
              value={reqQuantity}
              onChange={handleQuantity}
              inputType={settings.inputType.number}
              inputFormat={settings.inputFormat.int}
              alignment={settings.alignments.right}
              disabled={disabled}
              faded={greyOut}
              width={settings.dimensions.xsmall}
              onBlur={handleBlurQuantity}
            />
          ) : (
            <TableCell
              faded={greyOut}
              alignment={settings.alignments.right}
              width={settings.dimensions.xsmall}
            >
              { partialFulfilment
                ? <s>{ reqQuantity }</s>
                : reqQuantity
              }
            </TableCell>
          )
      );
      case TABLE_KEYS.fulfilledQty:
        if (mode === 'edit' && partialFulfilment) {
          return (
            <TableCell
              faded={greyOut}
              alignment={settings.alignments.right}
              width={settings.dimensions.xsmall}
            >
              { quantity }
            </TableCell>
          );
        }
        if (mode === 'fulfilment') {
          return (
            <TableTextInputCell
              id={`${lineItem?.id}-quantity`}
              label={localize(localeTableKeys.headings.quantity)}
              value={quantity}
              onChange={handleQuantity}
              inputType={settings.inputType.number}
              inputFormat={settings.inputFormat.float}
              alignment={settings.alignments.right}
              disabled={
                disabled
                  || !editing
                  || (mode === 'fulfilment' && !fulfilment)
              }
              faded={greyOut}
              width={settings.dimensions.xsmall}
              onBlur={handleBlurQuantity}
            />
          );
        }
        return <TableCell />;
      case TABLE_KEYS.unit: return (
        <TableCell faded={greyOut} alignment={settings.alignments.right}>
          { (quantity && unitPrice) ? `(× ${formatCurrency(unitPrice)})` : '' }
        </TableCell>
      );
      case TABLE_KEYS.price:
        return (
          <TableCell faded={greyOut} alignment={settings.alignments.right}>
            { adjusted
              ? (<s>{ unadjustedFormatted }</s>)
              : unadjustedFormatted
            }
          </TableCell>
        );
      case TABLE_KEYS.adjustedPrice:
        return ((mode === 'fulfilment') && !order?.paid)
          ? (
            <TableTextInputCell
              id={`${lineItem?.id}-price`}
              label={localize(localeTableKeys.headings.price)}
              value={ linePrice ? linePrice.calculatedValue : 0 }
              onChange={handlePrice}
              onBlur={handleBlurPrice}
              inputType={settings.inputType.number}
              inputFormat={settings.inputFormat.currency}
              alignment={settings.alignments.right}
            />
          ) : (linePrice?.amount !== unadjustedPrice?.amount) && (
            <TableCell faded={greyOut} alignment={settings.alignments.right}>
              { linePrice ? formatCurrency(linePrice) : '' }
            </TableCell>
          );
      case TABLE_KEYS.actions: return (
        <React.Fragment key={key}>
          { (!editing || (mode === 'fulfilment'))
            ? (generateActions && generateActions(lineItem || fallback))
            : gernerateEditActions()
          }
        </React.Fragment>
      );
      default: return <TableCell />;
    }
  }, [
    fallback,
    order,
    mode,
    disableProduct,
    disabled,
    icon,
    fade,
    lineItem,
    fulfilment,
    editing,
    product,
    period,
    quantity,
    reqQuantity,
    linePrice,
    unadjustedPrice,
    unitPrice,
    periodOptions,
    allProducts,
    handleProduct,
    handleQuantity,
    handlePrice,
    generateActions,
    gernerateEditActions,
    handleBlurQuantity,
    handleBlurPrice,
  ]);

  useEffect(() => {
    setPeriod(defaultPeriod ?? subscription?.period ?? 0)
  }, [subscription, defaultPeriod]);

  useEffect(() => {
    setPeriodOptions((lineItem && isLineItemRecurring(lineItem) && period)
      ? [1, 2, 3, 4]
      : [0, 1, 2, 3, 4]);
  }, [lineItem, period, isLineItemRecurring]);

  useEffect(() => { setAllProducts(listRecords(products)) }, [products]);

  useEffect(() => {
    setQuantity(fulfilment?.fulfilledQty
      ?? fulfilment?.requestedQty
      ?? subscription?.quantity
      ?? fallback?.quantity
      ?? null);
  }, [mode, fallback, subscription, fulfilment]);
  useEffect(() => {
    setReqQuantity(fulfilment?.requestedQty
      ?? subscription?.quantity
      ?? fallback?.quantity
      ?? null);
  }, [mode, fallback, subscription, fulfilment]);
  useEffect(() => {
    setLinePrice(
      lineItem
      ? calculateLinePrice(lineItem, order)
      : (product?.price ?? null)
    );
    setUnadjustedPrice(
      lineItem
      ? calculateLinePrice(
        lineItem,
        order,
        { adjustments : [], quantity : quantity },
      )
      : (product?.price ?? null)
    );
  }, [order, mode, lineItem, quantity, product, calculateLinePrice]);

  return (
    <>
      { tableKeys.map((key) => (
        <React.Fragment key={key}>
          { rowGenerator(key) }
        </React.Fragment>
      )) }
    </>
  );
}

function LineItemRow({
  lineItem,
  editing = false,
  ...props
} : LineItemRowProps) {

  return (
    <FormProvider
      init={lineItem}
      editing={editing}
    >
      <LineItemRowControl
        lineItem={lineItem}
        editing={editing}
        {...props}
      />
    </FormProvider>
  )
}

export default LineItemRow;
